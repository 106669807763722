import type { FC, HTMLAttributes } from "react";

export const PageHeading: FC<HTMLAttributes<HTMLHeadingElement>> = ({
  children,
}) => {
  return <h1 className="mb-8 text-3xl font-light font-serif">{children}</h1>;
};

export const PageSectionHeading: FC<HTMLAttributes<HTMLHeadingElement>> = ({
  children,
}) => {
  return <h2 className="mb-4 text-xl font-medium font-serif">{children}</h2>;
};

export const Paragraph: FC<HTMLAttributes<HTMLHeadingElement>> = ({
  children,
}) => {
  return <p className="mb-4 text-base font-normal">{children}</p>;
};
